import Tutorials from "../components/dashboard/Tutorials";

const strings = {
    en: {
        Marketing: {
            LogIn: 'Log In',
            HeroTitle: 'Video Prompts Without The Prep Work',
            HeroDescriptionBold: 'Elevate student engagement, enrich discussions, and enhance learning outcomes',
            HeroDescription: 'with innovative, AI-assisted video prompts that let students respond anytime, anywhere.',
            AIAssistedDiscussions: 'Instant Discussions',
            AIAssistedDiscussionsDescription: 'Record a video prompt right from your browser, attach documents or videos, and then invite the entire class to respond. Gravity\'s Educational AI can help you brainstorm discussions on any topic in the world.',
            KeywordsInsights: 'Keywords & Insights',
            KeywordsInsightsDescription: 'Gravity automatically generates full transcripts for each recording, extracts main keywords and provides deep insights, definitions, and related educational materials instantly.',
            DifferentiatedLearning: 'Differentiated Learning',
            DifferentiatedLearningDescription: 'Gravity also enables you to carve out a path that is unique to your student\'s learning style, vary instructional strategies, and provide multiple ways to express their understanding.',
            CallOut: 'Engagement, authentic opinions, discovery, and learning - all at once!',
            ThoughtEquity: 'Thought Equity',
            ThoughtEquityDescription: 'Gravity provides the time and space to give well-thought-out answers. No pop quiz responses, no race to raise your hand - everyone gets a chance to express their own unique point of view. It is more important than ever before to create a plurality of perspectives.',
            ResearchBacked: 'Research-Backed',
            ResearchBackedDescription: 'Gravity was selected for quantitative research at Johns Hopkins University School of Education and was found to significantly increase learning outcomes.',
            ByEducators: 'By Educators, For Educators',
            ByEducatorsDescription: 'Gravity was built by a team of teachers, professors, and technologists who believe in the power of education to change the world.',
            MagicAIPrompts: 'Premium Prompts',
            UnlimitedRecording: 'Unlimited Recording',
            AIVoiceToneAnalysis: 'Instantly translate and voice dub videos into 12+ world languages!',
            ReadyToSupercharge: 'Ready to supercharge your classroom?',
            YesIAm: 'Yes, I am - Let\'s go!',
            StartForFree: 'Start Free!',
            StartForFreeDescription: 'Start instantly with our Free version before committing',
            StartForFreeCallout: 'No Credit Card Required',
            AffordablePricing: 'A Plan For Everyone',
            AffordablePricingDescription: 'Creating a community is easy and affordable. Start for FREE with recording plans as low as $6 per month.',
            AffordablePricingCallout: '30% Discount on Yearly!',
            Privacy: 'Privacy Policy',
            TOS: 'Terms of Service'
        },
        Header: {
            Logout: 'Logout',
            EditProfile: 'Edit Profile',
            MonthlyRecordingLimit: 'Monthly Recording Limit',
            MinutesRemaining: 'Minutes Remaining',
            New: 'NEW',
            ComingSoon: 'COMING SOON',
            CommunityMarketplace: 'Find Out January 2025',
            CommunityMarketplaceText: 'A brand new way to discover...',
            IsHere: 'is here',
            VoiceOverText: 'Instantly translate and dub any Premium Prompt discussion into multiple languages',
            FollowUpText: 'Answer a personalized, AI-generated follow-up question based on your response',
        },
        Register: {
            Greeting: 'Howdy',
            GetRegistered: 'Let\'s get you registered',
            HaveJoinCode: 'I have a Join Code',
            CreateCommunity: 'Create a New Community',
            JoinCode: 'Join Code',
            NewCommunity: 'New Community',
            SixCode: 'Please enter your 6 character code',
            CommunityName: 'What do you want to name your community?',
            CreateAccount: 'Create Account',
            ToJoin: 'to join ',
            ThisCommunity: 'this community',
            ToStart: 'to start ',
            YourCommunity: 'your community',
            Now: ' now'
        },
        Dashboard: {
            BookDemo: 'Book a Gravity 15-Minute Live Demo for FREE!',
            Dashboard: 'Dashboard',
            Watched: 'Watched',
            Videos: 'Videos',
            Created: 'Created',
            Recordings: 'Recordings',
            Responded: 'Responded',
            Conversations: 'Conversations',
            CreatorStudio: 'Creator Studio',
            QuickPrompt: 'Quick Prompt',
            MagicAIPrompt: 'Premium Prompt',
            MagicAI: 'Gravity AI',
            Left: 'Left',
            Unlock: 'Unlock',
            RecentVideos: 'Recent Videos',
            Engagement: 'Engagement',
            QuickMenu: 'Quick Menu',
            CheckMirror: 'Check Mirror',
            Tutorials: 'Tutorials',
            TutorialsDescription: 'Tutorials and knowledge, just for you!',
            BackToDashboard: 'Back to Dashboard',
            BackToTutorials: 'Back to Tutorials',
        },
        Search: {
            NoResults: 'No results',
            Create: 'Create'
        },
        LoginRegister: {
            EmailAddress: 'Email Address',
            Password: 'Password',
            Login: 'Login',
            Register: 'Register',
            FullName: 'Full Name',
            FullNamePlaceholder: 'Jane Johnson',
            CommunitySearch: 'Community Search',
            CommunitySearchPlaceholder: 'Search for Community',
            CheckAvailability: 'Check Availability',
            AccountType: 'I am a',
            Uppercase: 'Uppercase Letter',
            Lowercase: 'Lowercase Letter',
            Number: 'Number',
            SpecialCharacter: 'Special Character',
            MinimumCharacters: 'Minimum Characters',
            RestoringSession: 'Restoring session',
            LogInToGravity: 'Log in to Gravity',
            OrAreYouNew: 'Or are you new?',
            ForgotPassword: 'Forgot Password?',
            Request: 'Request',
            GoBack: 'Go Back',
            RequestSent: 'Request Sent',
            Continue: 'Continue'
        },
        Package: {
            Basic: 'Basic',
            RecordAndShare: 'Record + Share',
            Free: 'Free',
            Includes: 'Includes',
            ThirtyMin: '30-Min Shared Recording Limit',
            Community: 'Community',
            Folders: 'Folders',
            GetStarted: 'Get Started',
            Premium: 'Premium',
            Unlimited: 'Unlimited',
            UnlimitedEverything: 'Unlimited Everything',
            AIWorkflows: 'AI Workflows',
            PerMonth: 'per month',
            EverythingInBasicPlus: 'Everything in Basic, plus',
            UnlimitedRecordingLimit: 'Unlimited Recording Limit',
            UnlimitedCommunities: 'Unlimited Communities',
            UnlimitedFolders: 'Unlimited Folders',
            AIVoiceToneAnalysis: 'AI VoiceOver',
            AIAutoTranscription: 'AI Auto Transcription',
            AIKeywordsAndInsights: 'AI Keywords & Insights',
            StartNow: 'Start Now',
            Enterprise: 'Enterprise',
            AdminAndAnalytics: 'Admin + Analytics',
            Custom: 'Custom',
            EverythingInPremiumPlus: 'Everything in Premium, plus',
            AnalyticsAndUsage: 'Analytics & Usage',
            DocumentAttachments: 'Document Attachments',
            DocumentAttachmentsDescription: 'Attach documents and Youtube videos to provide additional context!',
            MonthlyEngagementReports: 'Monthly Engagement Reports',
            DedicatedAccountManager: 'Dedicated Account Manager',
            TwentyFourSevenSupport: '24/7 Support',
            LetsTalk: 'Let\'s Talk',
            OneMinuteRecordingLimit: '1-Minute Recording Limit',
            UnlimitedMagicAIPrompts: 'Unlimited Premium Prompts',
            UnlimitedMagicAIPromptsDescription: 'Gravity Prompts create entire lessons with a single recording',
            UnlimitedRecordingLimitDescription: 'Record as much as you want without any limitations',
            AIVoiceToneAnalysisDescription: 'Instantly translate and voice dub videos into 12+ world languages on-demand!',
            AIAutoTranscriptionDescription: 'Increase accessibility, engagement, and learning outcomes',
            AIKeywordsAndInsightsDescription: 'Keywords extraction, instant definitions and suggested content',
            AnalyticsAndUsageDescription: 'Detailed reports usage, engagement, what\'s working and what\'s not',
            MagicAIPrompts: 'Premium Prompts',
            UnlimitedRecording: 'Unlimited Recording',
        },
        Pricing: {
            Basic: 'Basic',
            BasicPrice: 'Free',
            BasicDetails: 'Unlimited Participants for K-12 Educators',
            StartCreating: 'Participate, Record, and Share',
            RecordAnswerPrompts: 'Record and Answer Prompts',
            CreateCommunities: 'Create Communities',
            CreateNumberCommunities: 'Create up to 1 Community',
            CreateTwentyFiveParticipants: 'Invite up to 25 Participants',
            RecordUnlimitedPrompts: '<span className="unlimited">Unlimited</span> Prompt Recording',
            OneMinuteRecordingTime: '1-Minute Recording Time / Prompt',
            StartNow: 'Begin Basic',
            Premium: 'Premium',
            PremiumPrice: '$12/month',
            PremiumDetails: '50% Discount for K-12 Educators',
            AttachmentAIMore: 'AI, Attachments and More',
            CreateUnlimitedCommunities: '<span className="unlimited">Unlimited</span> Community Creation',
            InviteUnlimitedParticipants: '<span className="unlimited">Unlimited</span> Participant Invitations',
            UnlimitedRecordingTime: '<span className="unlimited">Unlimited</span> Recording Time / Prompt',
            AttachDocumentsVideos: '<span className="unlimited">Attach</span> Documents, Images and Videos',
            AIPoweredFeatures: '<span className="unlimited">AI-Powered</span> Creation Workflows',
            CreateNow: 'Start Premium',
        },
        Menu: {
            Select: 'Select',
            Create: 'Create',
            CommunityBegin: 'a community to begin.',
            Communities: 'Communities',
            Folders: 'Folders',

            AddCommunity: 'Add Community',
            AddCommunityDescription: 'Join or create a community for people to collaborate, converse and discover what\'s next.',
            AddCommunityNotAllowed: 'Join a community for people to collaborate, converse and discover what\'s next. To create a new community, upgrade to Premium.',
            AddCommunityName: 'Community Name',
            AddCommunityPlaceholder: 'ex. DEJ073',
            CreateCommunityPlaceholder: 'ex. Mulberry Street School',

            AddJoinCode: 'Enter Join Code',
            AddJoinCodeDescription: 'Join your classmate community to collaborate, converse and discover what\'s next.',
            AddJoinCodeName: 'Join Code',
            AddJoinCodePlaceholder: 'ex. 0XF78D',

            CreateFolder: 'Create a folder',
            CreateFolderDescription: 'Gather people in your community into focussed groups to discuss anything. Build collaborative conversations, polls and more.',
            CreateFolderName: 'Folder Name',
            CreateFolderPlaceholder: 'ex. European History Class',
            UniqueName: 'Unique Name',

            InviteMembers: 'Invite Members',
            InviteMembersDescription: 'Enter the email address of who to invite to your community.',
            InviteMembersPlaceholder: 'example@email.com',
            InviteButton: 'Send Invitation',
            InviteSent: 'Sent',

            ConfirmDelete: 'Delete Folder',
            ConfirmDeleteDescription: 'Are you sure you want to delete',
            ConfirmDeleteDiscussion: 'Delete Discussion',
            ConfirmDeleteResponse: 'Delete Response',
            ConfirmDeleteBackground: 'Delete Background',
            ConfirmDeleteBackgroundDescription: 'Are you sure you want to delete this background?',

            ConfirmRemoveMember: 'Remove Member',
            ConfirmRemoveMemberDescription: 'Are you sure you want to remove',
            ConfirmRemoveMemberDescription2: '',

            CreateFolderStart: 'Create a folder to start collaborating!',
            Duplicate: 'Duplicate',
            ConfirmDuplicate: 'Duplicate Folder',
            ConfirmDuplicateDescription: 'will be duplicated without the responses. Please enter a unique name for the new folder.',

            EditCommunities: 'Edit Communities',
            EditCommunitiesDescription: 'Rename, delete or duplicate your communities.',

            EditFolders: 'Edit Folders',
            EditFoldersDescription: 'Rename, delete or duplicate your folders.',
        },
        Modal: {
            Cancel: 'Cancel',
            Submit: 'Submit',
            Remove: 'Remove',
            SubmitContinue: 'Submit & Continue',
            Loading: 'Loading',
            Uploading: 'Uploading',
            Close: 'Close',
            Delete: 'Delete',
            ShortDescription: 'Prompt Description (Required)',
            ShortDescriptionPlaceholder: 'Please speak for 2 minutes about...',
            SpaceType: 'Space Type',
            Conversation: 'Conversation',
            ConversationDescription: 'Conversations allow you to record, respond and collaborate with classmates any time.',
            Poll: 'Poll',
            PollDescription: 'Ready to weigh options or reach concensus? Start a poll and let the community speak.',
            VideoRecording: 'Video Recording',
            AttachFile: 'Attach File',
            YoutubeLink: 'Attach Youtube',
            NarchivesLink: 'Attach National Archives',
            FigJamLink: 'Attach FigJam',
            GdriveLink: 'Attach Google Drive',
            CanvaLink: 'Attach Canva',
            ViewPreview: 'View Preview',
            ClosePreview: 'Close Preview',

            ChangeDescription: 'Change your full name or your profile image.',
            ProfileImage: 'Profile Image',
            ChangeHandle: '@ Name',
            ChangeHandlePlaceholder: 'Name (without @, without spaces)',

            JoinByCode: 'Join By Code',
            CreateNew: 'Create New',
            RelatedVideos: 'Related Videos',
            RelatedArticles: 'Related Articles',
            NoResults: 'No results',

            PremiumTitle: 'Unlock Premium',
            PremiumDescription: 'Unlock the most powerful AI workflow tools that save you hours of work, create entire lessons out of your prompts, and supercharge engagement',
            Upgrade: 'Unlock for $12 / Month',
            UpgradeYearly: 'Unlock for $99 / Year',
            UpgradeEducator: 'Unlock for $6 / Month',
            UpgradeYearlyEducator: 'Unlock for $58 / Year',
            YouArePremium: 'You are Premium',
            PremiumThankYou: 'thank you for being a',
            Monthly: 'Monthly',
            Yearly: 'Yearly',
            PremiumMember: 'Gravity Premium Member',
            NextRenewal: 'Next Renewal',
            EducatorDiscount: 'Save 50%',
            Savings: 'Save 30%',
            SavingsEducator: 'Save 60%',
            ContactUs: 'Contact Us',
            IAmEducator: 'I am a K-12 Educator! (50% Discount)',

            Yay: 'Yay!',
            Prompt: 'Prompt',
            Response: 'Response',
            Created: 'Created!',
            OnYourWay: 'You\'re now on your way to exciting collaboration!',
            Notify: 'Send Notifications',
            Return: 'Return',
            NotifyAll: 'Notify all',
            MembersOf: 'members of',

            AIPromptAssistant: 'AI Prompt Assistant',
            PromptScript: 'Prompt Script',
            PromptScriptPlaceholder: 'Paste your script here. This area will not be recorded.',
            Hide: 'Hide',
            Show: 'Show',
            Update: 'Update',
            Private: 'Private',
            New: 'New',

            Transcript: 'Transcript',
            Translation: 'Translation',
            SharePublic: 'Share Publicly',
            Next: 'Next',
            HeyThere: 'Hey there',
            FollowUpText: 'Based on your last response, I have a follow-up question for you',

            Leave: 'Leave',
            RecordEmbed: 'Recorder Embed Code',
            AllowPublic: 'Allow public responses to this prompt from anywhere',
            CopyCode: 'Copy Code',
            Copied: 'Copied!',
        },
        Content: {
            JoinCode: 'Join Code',
            Welcome: 'Welcome',
            NoUpdates: 'No updates since your last login',
            CreateASpace: 'Create New Video',

            CreateNewPrompt: 'Create New Prompt',
            CreateSpaceDescription: 'Record your face, voice or screen and get things started. Add information to help others collaborate!',
            CreateSpaceName: 'Prompt Title (Required)',
            CreateSpacePlaceholder: 'ex. Discussion on the Age of Enlightenment',
            ResponseTitle: 'Response Title (Required)', 

            NoPromptsYet: 'No prompts yet...',
            SelectAFolder: 'Select a Folder',

            Back: 'Back'
        },
        Recorder: {
            Video: 'Video',
            Screen: 'Screen',
            Voice: 'Voice',
            Combo: 'Combo',
            Upload: 'Upload',
            Resume: 'RESUME',
            Pause: 'PAUSE',
            Recording: 'RECORDING',

            Beta: 'BETA',
            New: 'NEW',

            Speaking: 'Speaking',
            Originality: 'Originality',
            Tone: 'Tone',
            Neutral: 'Neutral',
            Positive: 'Positive',
            Negative: 'Negative',

            FollowUp: 'Follow-Up Question',
            QuestionType: 'Question Type',

            InfoHover: 'Gravity leverages technology to detect responses by generative AI like ChatGPT. (This is an experimental feature)',
            ToneHover: 'Gravity leverages technology to help detect tone based on video transcript. (This is an experimental feature)',
            FollowUpHover: 'Leverage Gravity AI to create a personalized follow-up question following a response.',
            QuestionTypeHover: 'Select the style of the individualized follow-up question following a response.',
            
            PublishDate: 'Publish Date',
        },
        SpacesView: {
            Conversation: 'Conversation',
            Poll: 'Poll',
            Members: 'Members',
            Joined: 'Joined',
            Anonymous: 'Anonymous',
            ScheduledFor: 'Scheduled for',
            View: 'View',
            Hide: 'Hide',
            Response: 'Response',
            Responses: 'Responses',
        },
        AssetsView: {
            Teacher: 'Teacher',
            Student: 'Student',
            Respond: 'Respond',
            To: ' to',
            RespondTo: 'Respond to',
            DocumentAttachment: 'Document',
            YoutubeAttachment: 'YouTube',
            NarchivesAttachment: 'National Archives',
            NoResponses: 'No responses yet',
            View: 'View',
            Reply: 'Reply',
            Replies: 'Replies',

            ResponseDescriptionPlaceholder: 'ex. Before we make a decision...',
            SidebarWith: 'Sidebar with',
            ThreadDescriptionPlaceholder: 'ex. Could you expand on that detail a bit...',

            Back: 'Back to',
            Keywords: 'Keywords',
            Insights: 'Insights',
            EditKeywords: 'Edit Keywords',
            EditKeywordsDescription: 'Please keep a minimum of 5 keywords',

            Settings: 'Prompt Settings',
            SettingsDescription: 'Control the features of your prompt, edit the publish date, and more.',
            Record: 'Record',
            Text: 'Text',

            AIGenerated: 'AI-Generated Follow-Up Question',
            ClickFor: 'Click for',

            SorryVideo: 'Sorry, the media is not loading...',
            Retry: 'Retry',
        },
        ThreadView: {
            Collapse: 'Collapse',
            Back: 'Back to prompt',
            TypeResponse: 'Type a response...',
            Submitted: 'Sent!',
            Failed: 'Fail',
        },
        Upload: {
            DropFileHere: 'Drop file here',
            Or: 'OR',
            SelectFile: 'Select file',
            SelectVideo: 'Select .mp4',
            SetAsVideo: 'Set As Video',
            FileName: 'File Name',
            FileType: 'File Type',
            Reselect: 'Reselect'
        },
        Video: {
            Transcripts: '*Transcripts are automatically generated. Inaccuracies may occur.',
            Select: 'Select',
            English: 'English',
            Spanish: 'Spanish',
            French: 'French',
            German: 'German',
            Italian: 'Italian',
            Portuguese: 'Portuguese',
            Dutch: 'Dutch',
            Russian: 'Russian',
            Chinese: 'Chinese',
            Japanese: 'Japanese',
            Korean: 'Korean',
            Arabic: 'Arabic',
            Hindi: 'Hindi'
        },
        Embed: {
            PoweredBy: 'Powered by',
            JoinConversation: 'Join This Conversation',
            EnterDetails: 'Enter details below',
            BackGuest: 'Back to Guest Login',
            OrUse: 'or use your',
            ToGetStarted: 'to get started!',
            Name: 'Name',
            Email: 'Email',
            StartRecording: 'Start Recording',
            Success: 'Success!',
            StayTuned: 'Stay tuned for more WRAD prompts!',
            VisitGravity: 'Visit usegravity.io for more collaboration!',
            Sorry: 'Sorry, this prompt is not embeddable',
        }
    },
    zh: {
        Marketing: {
            LogIn: '登录',
            HeroTitle: '无需准备的视频提示',
            HeroDescriptionBold: '提高学生参与度，丰富讨论，提高学习成果',
            HeroDescription: '使用创新的AI辅助视频提示，学生可以随时随地回复。',
            AIAssistedDiscussions: 'AI辅助讨论',
            AIAssistedDiscussionsDescription: '从浏览器中录制视频提示，附加文档或视频，然后邀请整个班级进行回复。 Gravity的教育AI可以帮助您在世界上任何主题上进行头脑风暴讨论。',
            KeywordsInsights: '关键字和见解',
            KeywordsInsightsDescription: 'Gravity会自动生成每个录音的完整转录，提取主要关键字并立即提供深入见解、定义和相关教育材料。',
            DifferentiatedLearning: '差异化学习',
            DifferentiatedLearningDescription: 'Gravity还使您能够为学生的学习风格开辟一条独特的道路，变化的教学策略，并提供多种表达理解的方式。',
            CallOut: '参与度、真实观点、发现和学习-一次性完成！',
            ThoughtEquity: '思想平等',
            ThoughtEquityDescription: 'Gravity提供了时间和空间来给出深思熟虑的答案。 没有弹出式测验响应，没有竞赛举手-每个人都有机会表达自己独特的观点。 创造多元化的观点比以往任何时候都更重要。',
            ResearchBacked: '研究支持',
            ResearchBackedDescription: 'Gravity被约翰霍普金斯大学教育学院选为定量研究，并发现其显着提高了学习成果。',
            ByEducators: '由教育工作者制作，供教育工作者使用',
            ByEducatorsDescription: 'Gravity由一组教师、教授和技术人员构建，他们相信教育的力量可以改变世界。',
            MagicAIPrompts: 'Premium提示',
            UnlimitedRecording: '无限录制',
            AIVoiceToneAnalysis: '立即将视频配音成 12 种以上的世界语言！',
            ReadyToSupercharge: '准备为您的课堂提速吗？',
            YesIAm: '是的，我是 - 让我们走！',
            StartForFree: '免费开始！',
            StartForFreeDescription: '在承诺之前，立即开始使用我们的免费版本',
            StartForFreeCallout: '无需信用卡',
            AffordablePricing: '实惠的定价',
            AffordablePricingDescription: '创建社区既简单又实惠。 从每月6美元的录制计划开始免费。',
            AffordablePricingCallout: '年度优惠30％！',
            Privacy: '隐私政策',
            TOS: '服务条款'
        },
        Header: {
            Logout: '登出',
            EditProfile: '编辑个人资料',
            MonthlyRecordingLimit: '每月录制限制',
            MinutesRemaining: '剩余分钟',
            New: '新',
            ComingSoon: '即将推出',
            CommunityMarketplace: '发现2025年1月',
            CommunityMarketplaceText: '发现全新的方式...',
            IsHere: '在这里',
            VoiceOverText: '立即将任何Premium提示讨论瞬间翻译和配音成多种语言',
            FollowUpText: '根据您的回答回答由 AI 生成的个性化后续问题',
        },
        Register: {
            Greeting: '你好',
            GetRegistered: '让我们注册',
            HaveJoinCode: '我有一个加入代码',
            CreateCommunity: '创建一个新社区',
            JoinCode: '加入代码',
            NewCommunity: '新社区',
            SixCode: '请输入您的6位字符代码',
            CommunityName: '您想给社区起什么名字？',
            CreateAccount: '创建帐号',
            ToJoin: '加入',
            ThisCommunity: '这个社区',
            ToStart: '开始',
            YourCommunity: '你的社区',
            Now: '现在'
        },
        Dashboard: {
            BookDemo: '免费预订 Gravity 15 分钟现场演示!',
            Dashboard: '仪表板',
            Watched: '已观看',
            Videos: '视频',
            Created: '创建',
            Recordings: '录音',
            Responded: '已回复',
            Conversations: '对话',
            CreatorStudio: '创作者工作室',
            QuickPrompt: '快速提示',
            MagicAIPrompt: 'Premium提示',
            MagicAI: 'Gravity AI',
            Left: '剩余',
            Unlock: '解锁',
            RecentVideos: '最近的视频',
            Engagement: '参与度',
            QuickMenu: '快速菜单',
            CheckMirror: '检查镜像',
            Tutorials: '教程',
            TutorialsDescription: '教程和知识，专为您而设',
            BackToDashboard: '返回仪表板',
            BackToTutorials: '返回教程',
        },
        Search: {
            NoResults: '没有结果',
            Create: '创建'
        },
        LoginRegister: {
            EmailAddress: '电子邮件地址',
            Password: '密码',
            Login: '登录',
            Register: '登记',
            FullName: '全名',
            FullNamePlaceholder: '晓明',
            CommunitySearch: '社区搜索',
            CommunitySearchPlaceholder: '搜索社区',
            CheckAvailability: '检查可用性',
            AccountType: '我是',
            Uppercase: '大写字母',
            Lowercase: '小写字母',
            Number: '数字',
            SpecialCharacter: '特殊字符',
            MinimumCharacters: '最少字符',
            RestoringSession: '恢复会话...',
            LogInToGravity: '登录Gravity',
            OrAreYouNew: '新用户',
            ForgotPassword: '忘记密码？',
            Request: '要求',
            GoBack: '回去',
            RequestSent: '请求已发送',
            Continue: '继续'
        },
        Package: {
            Basic: '基本',
            RecordAndShare: '录制+分享',
            Free: '免费',
            Includes: '包括',
            ThirtyMin: '30分钟共享录制限制',
            Community: '社区',
            Folders: '文件夹',
            GetStarted: '开始',
            Premium: '高级',
            Unlimited: '无限',
            UnlimitedEverything: '无限一切',
            AIWorkflows: 'AI工作流',
            PerMonth: '每月',
            EverythingInBasicPlus: '基本功能加上',
            UnlimitedRecordingLimit: '无限录制限制',
            UnlimitedCommunities: '无限社区',
            UnlimitedFolders: '无限文件夹',
            AIVoiceToneAnalysis: 'AI语音翻译',
            AIAutoTranscription: 'AI自动转录',
            AIKeywordsAndInsights: 'AI关键字和见解',
            StartNow: '现在开始',
            Enterprise: '企业',
            AdminAndAnalytics: '管理+分析',
            Custom: '习惯',
            EverythingInPremiumPlus: '高级版中的所有内容，加上',
            AnalyticsAndUsage: '分析和使用情况',
            DocumentAttachments: '文档附件',
            DocumentAttachmentsDescription: '附加文档和Youtube视频以提供更多上下文！',
            MonthlyEngagementReports: '每月参与报告',
            DedicatedAccountManager: '专属客户经理',
            TwentyFourSevenSupport: '24/7支持',
            LetsTalk: '让我们谈谈',
            OneMinuteRecordingLimit: '1分钟录制限制',
            UnlimitedMagicAIPrompts: '无限PremiumI提示',
            UnlimitedMagicAIPromptsDescription: 'Gravity提示通过单个录音创建整个课程',
            UnlimitedRecordingLimitDescription: '无限制地录制任何内容',
            AIVoiceToneAnalysisDescription: '立即按需将视频翻译并配音成 12 种以上的世界语言！',
            AIAutoTranscriptionDescription: '提高可访问性、参与度和学习成果',
            AIKeywordsAndInsightsDescription: '关键字提取、即时定义和建议内容',
            AnalyticsAndUsageDescription: '详细的报告使用情况、参与度、什么有效、什么无效',
            MagicAIPrompts: 'Premium提示',
            UnlimitedRecording: '无限录制',
        },
        Pricing: {
            Basic: '基本',
            BasicPrice: '免费',
            BasicDetails: 'K-12教育工作者无限参与者',
            StartCreating: '参与、录制和分享',
            RecordAnswerPrompts: '记录和回答提示',
            CreateCommunities: '创建社区',
            CreateNumberCommunities: '创建多达1个社区',
            CreateTwentyFiveParticipants: '邀请多达25名参与者',
            RecordUnlimitedPrompts: '<span className="unlimited">无限</span>提示录制',
            OneMinuteRecordingTime: '1分钟录制时间/提示',
            StartNow: '开始基本',
            Premium: '高级',
            PremiumPrice: '$12/月',
            PremiumDetails: 'K-12教育工作者50%折扣',
            AttachmentAIMore: 'AI、附件和更多',
            CreateUnlimitedCommunities: '<span className="unlimited">无限</span>社区创建',
            InviteUnlimitedParticipants: '<span className="unlimited">无限</span>参与者邀请',
            UnlimitedRecordingTime: '<span className="unlimited">无限</span>录制时间/提示',
            AttachDocumentsVideos: '<span className="unlimited">附加</span>文档、图像和视频',
            AIPoweredFeatures: '<span className="unlimited">AI驱动</span>创建工作流',
            CreateNow: '开始高级',
        },
        Menu: {
            Select: '选择',
            Create: '创建',
            CommunityBegin: '一个社区开始.',
            Communities: '社区',
            Folders: '文件夹',

            AddCommunity: '添加社区',
            AddCommunityDescription: '加入或建立下一个供人们协作、交谈和探索下一步的好地方。 使用 Gravity 将人们带到同一张桌子旁。',
            AddCommunityNotAllowed: '加入或建立下一个供人们协作、交谈和探索下一步的好地方。 要创建新社区，请升级到高级版。',
            AddCommunityName: '社区名字',
            AddCommunityPlaceholder: '例如: DEJ073',
            CreateCommunityPlaceholder: '例如: 桑树街学校',

            AddJoinCode: '输入加入密码',
            AddJoinCodeDescription: '加入您的同学社区进行协作、交谈并探索下一步.',
            AddJoinCodeName: '加入代码',
            AddJoinCodePlaceholder: '例如: 0XF78D',

            CreateFolder: '创建一个文件夹',
            CreateFolderDescription: '将社区中的人们聚集到集中的小组中讨论任何事情。 建立协作对话、投票等。',
            CreateFolderName: '文件夹名称',
            CreateFolderPlaceholder: '例如: 欧洲历史课',

            ConfirmDelete: '删除文件夹',
            ConfirmDeleteDescription: '你确定你要删除',
            ConfirmDeleteDiscussion: '删除讨论',
            ConfirmDeleteResponse: '删除回复',
            ConfirmDeleteBackground: '删除背景',
            ConfirmDeleteBackgroundDescription: '您确定要删除该背景吗?',

            ConfirmRemoveMember: '移除成员',
            ConfirmRemoveMemberDescription: '您确定要移除',
            ConfirmRemoveMemberDescription2: '吗',

            CreateFolderStart: '创建文件夹以开始协作',
            Duplicate: '复制',
            ConfirmDuplicate: '重复文件夹',
            ConfirmDuplicateDescription: '将在没有响应的情况下重复。 请为新文件夹输入唯一的名称。',
            UniqueName: '独特的名字',

            InviteMembers: '邀请成员',
            InviteMembersDescription: '输入您要邀请到您的社区的电子邮件地址。',
            InviteMembersPlaceholder: 'example@email.com',
            InviteButton: '发送邀请',
            InviteSent: '发送',

            EditCommunities: '编辑社区',
            EditCommunitiesDescription: '重命名、删除或复制您的社区。',

            EditFolders: '编辑文件夹',
            EditFoldersDescription: '重命名、删除或复制您的文件夹。',
        },
        Modal: {
            Cancel: '取消',
            Submit: '提交',
            Remove: '消除',
            SubmitContinue: '提交并继续',
            Loading: '加载中',
            Uploading: '上传',
            Close: '关闭',
            ShortDescription: '提示描述(必需的)',
            ShortDescriptionPlaceholder: '例如: 请讲两分钟关于...',
            SpaceType: '空间类型',
            Conversation: '对话',
            ConversationDescription: '对话让您可以随时记录、回复和与同学协作。',
            Poll: '轮询',
            PollDescription: '准备好权衡选择或达成共识了吗？ 发起民意调查，让社区发言。',
            VideoRecording: '视频录制',
            AttachFile: '附加文件',
            YoutubeLink: 'YouTube网址',
            NarchivesLink: '国家档案馆链接',
            FigmaLink: 'FigJam链接',
            GdriveLink: 'Google Drive链接',
            CanvaLink: 'Canva链接',
            ViewPreview: '查看预览',
            ClosePreview: '关闭预览',

            ChangeDescription: '更改您的全名或个人资料图片。',
            ProfileImage: '个人资料图片',
            ChangeHandle: '@ 名字',
            ChangeHandlePlaceholder: '名字 (没有@, 没有空间)',

            JoinByCode: '加入代码',
            CreateNew: '创建新的',
            RelatedVideos: '有关的影片',
            RelatedArticles: '有关的文章',
            NoResults: '没有结果',

            PremiumTitle: '升级到高级版',
            PremiumDescription: '即可解锁最强大的 AI 工作流工具，可节省您数小时的工作时间，通过自动建议创建整个课程，并大大提高参与度',
            Upgrade: '解锁 $12 / 月',
            UpgradeYearly: '解锁 $99 / 年',
            UpgradeEducator: '解锁 $6 / 月',
            UpgradeYearlyEducator: '解锁 $58 / 年',
            YouArePremium: '您是高级版',
            PremiumThankYou: '感谢您成为',
            Monthly: '每月',
            Yearly: '每年',
            PremiumMember: 'Gravity高级会员。',
            NextRenewal: '下次续订',
            EducatorDiscount: '50% 折扣',
            Savings: '30% 折扣',
            SavingsEducator: '60% 折扣',
            ContactUs: '联系我们',
            IAmEducator: '我是 K-12 教育工作者！ (50% 折扣)',

            Yay: '耶!',
            Prompt: '提示',
            Response: '空间',
            Created: '创建!',
            OnYourWay: '您现在正在进行令人兴奋的协作!',
            Notify: '发送通知',
            Return: '返回',
            NotifyAll: '通知所有',
            MembersOf: '的成员',

            AIPromptAssistant: 'AI提示助手',
            PromptScript: '提示脚本',
            PromptScriptPlaceholder: '在这里粘贴您的脚本。 此区域不会被记录。',
            Hide: '隐藏',
            Show: '显示',
            Update: '更新',
            Private: '私人',
            New: '新',

            Transcript: '记录',
            Translation: '翻译',
            SharePublic: '分享公开',
            Next: '下一个',
            HeyThere: '你好呀',
            FollowUpText: '根据你上次的回复，我有一个后续问题要问你',

            Leave: '离开',
            RecordEmbed: '录音机嵌入代码',
            AllowPublic: '允许从任何地方公开回应此提示',
            CopyCode: '复制代码',
            Copied: '已复制!',
        },
        Content: {
            JoinCode: '加入代码',
            Welcome: '欢迎',
            NoUpdates: '自您上次登录以来没有更新',
            CreateASpace: '创造新空间',

            CreateNewPrompt: '创建新提示',
            CreateSpaceDescription: '录制您的面部、声音或屏幕，然后开始工作。 添加信息以帮助其他人协作!',
            CreateSpaceName: '提示标题(必需的)',
            CreateSpacePlaceholder: '例如: 启蒙时代的探讨',
            ResponseTitle: '回复标题(必需的)', 

            NoPromptsYet: '还没有提示',
            SelectAFolder: '选择一个文件夹',

            Back: '返回'
        },
        Recorder: {
            Video: '视频',
            Screen: '屏幕',
            Voice: '语音',
            Combo: '组合',
            Upload: '上传',
            Resume: '恢复',
            Pause: '暂停',
            Recording: '记录的',

            Beta: '测试版',
            New: '新的',

            Speaking: '发言',
            Originality: '独创性',
            Tone: '笔调',
            Neutral: '中性',
            Positive: '正面',
            Negative: '负面',

            FollowUp: '跟进问题',
            QuestionType: '问题类型',

            InfoHover: 'Gravity利用技术来检测生成AI的响应,例如ChatGPT. (这是一个实验性功能)',
            ToneHover: 'Gravity 利用技术帮助检测基于视频转录的音调. (这是一个实验性功能)',
            FollowUpHover: '利用 Gravity AI 在回复后创建个性化的后续问题。',
            QuestionTypeHover: '选择答复后的个性化后续问题的样式。',

            PublishDate: '发布日期',
        },
        SpacesView: {
            Conversation: '对话',
            Poll: '轮询',
            Members: '成员',
            Joined: '已加入',
            Anonymous: '匿名',
            ScheduledFor: '计划于',
            View: '查看',
            Hide: '隐藏',
            Response: '回应',
            Responses: '回应',
        },
        AssetsView: {
            Teacher: '老师',
            Student: '学生',
            Respond: '回复',
            To: '',
            RespondTo: '回复',
            DocumentAttachment: '文件附件',
            YoutubeAttachment: 'YouTube附件',
            NarchivesAttachment: '国家档案馆附件',
            NoResponses: '还没有回复',
            View: '查看',
            Reply: '回复',
            Replies: '个回复',

            ResponseDescriptionPlaceholder: '例如: 在我们做出决定之前...',
            SidebarWith: '侧边栏',
            ThreadDescriptionPlaceholder: '例如: 你能把这个细节扩展一下吗...',

            Back: '返回',
            Keywords: '关键词',
            Insights: '更多信息',
            EditKeywords: '编辑关键词',
            EditKeywordsDescription: '请保留至少5个关键字',

            Settings: '提示设置',
            SettingsDescription: '控制提示的功能，编辑发布日期等。',
            Record: '记录',
            Text: '文本',

            AIGenerated: 'AI生成的后续问题',
            ClickFor: '点击查看',

            SorryVideo: '抱歉，媒体未加载...',
            Retry: '重试',
        },
        ThreadView: {
            Collapse: '关闭',
            Back: '返回提示',
            TypeResponse: '输入回复...',
            Submitted: '发送!',
            Failed: '失败的',
        },
        Upload: {
            DropFileHere: '把文件放在这里',
            Or: '或',
            SelectFile: '选择文件',
            SelectVideo: '选择.mp4',
            SetAsVideo: '设置为视频',
            FileName: '文件名',
            FileType: '文件类型',
            Reselect: '重新选择'
        },
        Video: {
            Transcripts: '*记录是自动生成的。 可能会出现不准确的情况。',
            Select: '选择',
            English: '英语',
            Spanish: '西班牙语',
            French: '法语',
            German: '德语',
            Italian: '意大利语',
            Portuguese: '葡萄牙语',
            Dutch: '荷兰语',
            Russian: '俄语',
            Chinese: '中文',
            Japanese: '日语',
            Korean: '韩语',
            Arabic: '阿拉伯语',
            Hindi: '印地语'
        },
        Embed: {
            PoweredBy: '供電',
            JoinConversation: '加入此對話',
            EnterDetails: '在下面输入详细信息',
            BackGuest: '返回访客登录',
            ToGetStarted: '开始！',
            OrUse: '或使用您的',
            Name: '名字',
            Email: '电子邮件',
            StartRecording: '开始录制',
            Success: '已连接!',
            StayTuned: '请继续关注更多WRAD提示!',
            VisitGravity: '访问 usegravity.io 获得更多合作!',
            Sorry: '抱歉，此提示不可嵌入',
        }
    },
    nl: {
        Marketing: {
            LogIn: 'Log in',
            HeroTitle: 'Video Prompts zonder de voorbereiding',
            HeroDescriptionBold: 'Verhoog de betrokkenheid van studenten, verrijk discussies en verbeter leerresultaten',
            HeroDescription: 'Met innovatieve, door AI ondersteunde videoprompts waarmee studenten altijd en overal kunnen reageren.',
            AIAssistedDiscussions: 'AI-ondersteunde discussies',
            AIAssistedDiscussionsDescription: 'Neem een ​​videoprompt rechtstreeks op vanuit uw browser, voeg documenten of video\'s toe en nodig vervolgens de hele klas uit om te reageren. Gravity\'s Educational AI kan u helpen om over elk onderwerp ter wereld te brainstormen.',
            KeywordsInsights: 'Trefwoorden en inzichten',
            KeywordsInsightsDescription: 'Gravity genereert automatisch een volledige transcriptie van elke opname, extraheert belangrijke trefwoorden en biedt direct diepgaande inzichten, definities en gerelateerd educatief materiaal.',
            DifferentiatedLearning: 'Gedifferentieerd leren',
            DifferentiatedLearningDescription: 'Gravity stelt u ook in staat om een ​​unieke weg te banen voor de leerstijl van studenten, variërende onderwijsstrategieën te implementeren en meerdere manieren te bieden om begrip uit te drukken.',
            CallOut: 'Betrokkenheid, echte standpunten, ontdekking en leren - allemaal in één keer!',
            ThoughtEquity: 'Gedachtegelijkheid',
            ThoughtEquityDescription: 'Gravity biedt tijd en ruimte om doordachte antwoorden te geven. Geen pop-up quizantwoorden, geen wedstrijdhanden - iedereen krijgt de kans om zijn unieke standpunt te uiten. Het creëren van diverse perspectieven is belangrijker dan ooit.',
            ResearchBacked: 'Onderzoek ondersteund',
            ResearchBackedDescription: 'Gravity is geselecteerd door de Johns Hopkins School of Education voor kwantitatief onderzoek en bleek de leerresultaten aanzienlijk te verbeteren.',
            ByEducators: 'Gemaakt door opvoeders, voor opvoeders',
            ByEducatorsDescription: 'Gravity is gebouwd door een groep leraren, professoren en technici die geloven dat de kracht van onderwijs de wereld kan veranderen.',
            MagicAIPrompts: 'Premium Prompts',
            UnlimitedRecording: 'Onbeperkt opnemen',
            AIVoiceToneAnalysis: 'Vertaal en spreek elk Premium Prompt-discussiemoment direct in 12+ wereldtalen!',
            ReadyToSupercharge: 'Klaar om uw klas te versnellen?',
            YesIAm: 'Ja, dat ben ik - laten we gaan!',
            StartForFree: 'Start gratis!',
            StartForFreeDescription: 'Begin met onze gratis versie voordat u zich verbindt',
            StartForFreeCallout: 'Geen creditcard nodig',
            AffordablePricing: 'Betaalbare prijzen',
            AffordablePricingDescription: 'Het maken van gemeenschappen is eenvoudig en betaalbaar. Begin met opnameplannen vanaf $6 per maand.',
            AffordablePricingCallout: '30% korting op jaarbasis!',
            Privacy: 'Privacybeleid',
            TOS: 'Servicevoorwaarden'
        },
        Header: {
            Logout: 'Uitloggen',
            EditProfile: 'Bewerk profiel',
            MonthlyRecordingLimit: 'Maandelijkse opnamelimiet',
            MinutesRemaining: 'Minuten over',
            New: 'NIEUW',
            ComingSoon: 'BINNENKORT',
            CommunityMarketplace: 'Ontdek in Januari 2025',
            CommunityMarketplaceText: 'Ontdek nieuwe manieren...',
            IsHere: 'is hier',
            VoiceOverText: 'Vertaal en spreek elk Premium Prompt-discussiemoment direct in meerdere talen',
            FollowUpText: 'Beantwoord een gepersonaliseerde, door AI gegenereerde vervolgvraag op basis van uw antwoord',
        },
        Register: {
            Greeting: 'Hallo',
            GetRegistered: 'Laten we ons registreren',
            HaveJoinCode: 'Ik heb een join-code',
            CreateCommunity: 'Maak een nieuwe community',
            JoinCode: 'Join Code',
            NewCommunity: 'Nieuwe community',
            SixCode: 'Voer uw 6-cijferige code in',
            CommunityName: 'Hoe wilt u uw community noemen?',
            CreateAccount: 'Account aanmaken',
            ToJoin: 'om mee te doen ',
            ThisCommunity: 'deze community',
            ToStart: 'start ',
            YourCommunity: 'jouw community',
            Now: ' nu'
        },
        Dashboard: {
            BookDemo: 'Boek GRATIS een live demo van 15 minuten van Gravity!',
            Dashboard: 'Dashboard',
            Watched: 'Bekeken',
            Videos: 'Videos',
            Created: 'Gemaakt',
            Recordings: 'Opnames',
            Responded: 'Gereageerd',
            Conversations: 'Gesprekken',
            CreatorStudio: 'Creator Studio',
            QuickPrompt: 'Snelle prompt',
            MagicAIPrompt: 'Premium Prompt',
            MagicAI: 'Gravity AI',
            Left: 'Over',
            Unlock: 'Ontgrendelen',
            RecentVideos: 'Recente videos',
            Engagement: 'Betrokkenheid',
            QuickMenu: 'Snelle menu',
            CheckMirror: 'Controleer spiegel',
            Tutorials: 'Zelfstudies',
            TutorialsDescription: 'Tutorials en kennis, speciaal voor jou!',
            BackToDashboard: 'Terug naar dashboard',
            BackToTutorials: 'Terug naar zelfstudies',
        },
        Search: {
            NoResults: 'Geen resultaten',
            Create: 'Creëren'
        },
        LoginRegister: {
            EmailAddress: 'E-mailadres',
            Password: 'Wachtwoord',
            Login: 'Log in',
            Register: 'Register',
            FullName: 'Voor-en Achternaam',
            FullNamePlaceholder: 'Julia Jansen',
            CommunitySearch: 'Community Zoeken',
            CommunitySearchPlaceholder: 'Zoeken naar gemeenschap',
            CheckAvailability: 'Beschikbaarheid Controleren',
            AccountType: 'Ik ben een',
            Uppercase: 'Hoofdletter',
            Lowercase: 'Kleine Letter',
            Number: 'Nummer',
            SpecialCharacter: 'Speciaal Karakter',
            MinimumCharacters: 'Minimale Tekens',
            RestoringSession: 'Sessie herstellen...',
            LogInToGravity: 'Inloggen op Gravity',
            OrAreYouNew: 'Of ben je nieuw?',
            ForgotPassword: 'Wachtwoord vergeten?',
            Request: 'Verzoek',
            GoBack: 'Ga terug',
            RequestSent: 'Verzoek verzonden',
            Continue: 'Doorgaan'
        },
        Package: {
            Basic: 'Basis',
            RecordAndShare: 'Opnemen + delen',
            Free: 'Vrij',
            Includes: 'Inclusief',
            ThirtyMin: 'Gedeelde opnamelimiet van 30 minuten',
            Community: 'Gemeenschap',
            Folders: 'Mappen',
            GetStarted: 'Aan de slag',
            Premium: 'Premium',
            Unlimited: 'Onbeperkt',
            UnlimitedEverything: 'Onbeperkt alles',
            AIWorkflows: 'AI Workflows',
            PerMonth: 'per maand',
            EverythingInBasicPlus: 'Alles in Basic, plus',
            UnlimitedRecordingLimit: 'Onbeperkte opnamelimiet',
            UnlimitedCommunities: 'Onbeperkte gemeenschappen',
            UnlimitedFolders: 'Onbeperkte mappen',
            AIVoiceToneAnalysis: 'AI-Stem dub',
            AIAutoTranscription: 'AI Automatische transcriptie',
            AIKeywordsAndInsights: 'AI Trefwoorden en inzichten',
            StartNow: 'Begin nu',
            Enterprise: 'Onderneming',
            AdminAndAnalytics: 'Beheer + analyse',
            Custom: 'Gewoonte',
            EverythingInPremiumPlus: 'Alles in Premium, plus',
            AnalyticsAndUsage: 'Analyse en gebruik',
            DocumentAttachments: 'Document Video Bijlagen',
            DocumentAttachmentsDescription: 'Voeg documenten en Youtube-video\'s toe om extra context te bieden!',
            MonthlyEngagementReports: 'Maandelijkse betrokkenheidsrapporten',
            DedicatedAccountManager: 'Toegewijde accountmanager',
            TwentyFourSevenSupport: '24/7 Ondersteuning',
            LetsTalk: 'Laten we praten',
            OneMinuteRecordingLimit: '1 minuut opnamelimiet',
            UnlimitedMagicAIPrompts: 'Onbeperkte Premium Prompts',
            UnlimitedMagicAIPromptsDescription: 'Gravity Prompts creëren hele lessen met een enkele opname',
            UnlimitedRecordingLimitDescription: 'Neem zoveel op als je wilt zonder beperkingen',
            AIVoiceToneAnalysisDescription: 'Vertaal en spreek elk Premium Prompt-discussiemoment direct in 12+ wereldtalen!',
            AIAutoTranscriptionDescription: 'Verhoog de toegankelijkheid, betrokkenheid en leerresultaten',
            AIKeywordsAndInsightsDescription: 'Trefwoorden extractie, directe definities en voorgestelde inhoud',
            AnalyticsAndUsageDescription: 'Gedetailleerde rapporten gebruik, betrokkenheid, wat werkt en wat niet',
            MagicAIPrompts: 'Premium Prompts',
            UnlimitedRecording: 'Onbeperkt opnemen',
        },
        Pricing: {
            Basic: 'Basis',
            BasicPrice: 'Gratis',
            BasicDetails: 'K-12 opvoeders onbeperkte deelnemers',
            StartCreating: 'Deelnemen, opnemen en delen',
            RecordAnswerPrompts: 'Record en beantwoord prompts',
            CreateCommunities: 'Maak Gemeenschappen',
            CreateNumberCommunities: 'Maak maximaal 1 Gemeenschap',
            CreateTwentyFiveParticipants: 'Nodig tot 25 deelnemers uit',
            RecordUnlimitedPrompts: '<span className="unlimited">Onbeperkt</span> prompts opnemen',
            OneMinuteRecordingTime: '1 minuut opnametijd / prompt',
            StartNow: `Beginnen`,
            Premium: 'Premium',
            PremiumPrice: '$12/maand',
            PremiumDetails: 'K-12 opvoeders 50% korting',
            AttachmentAIMore: 'AI, bijlagen en meer',
            CreateUnlimitedCommunities: '<span className="unlimited">Onbeperkt</span> Gemeenschappen maken',
            InviteUnlimitedParticipants: '<span className="unlimited">Onbeperkt</span> deelnemers uitnodigen',
            UnlimitedRecordingTime: '<span className="unlimited">Onbeperkt</span> opnametijd / prompt',
            AttachDocumentsVideos: '<span className="unlimited">Documenten</span>, afbeeldingen en video\'s bijvoegen',
            AIPoweredFeatures: '<span className="unlimited">AI-aangedreven</span> workflow maken',
            CreateNow: 'Beginnen met Premium',
        },
        Menu: {
            Select: 'Selecteer',
            Create: 'Maak',
            CommunityBegin: 'een community om te beginnen.',
            Communities: 'Gemeenschappen',
            Folders: 'Mappen',

            AddCommunity: 'Community Toevoegen',
            AddCommunityDescription: 'Doe mee of bouw de volgende geweldige plek waar mensen kunnen samenwerken, praten en ontdekken wat de toekomst biedt. Breng mensen rond dezelfde tafel met Gravity.',
            AddCommunityNotAllowed: 'Doe mee of bouw de volgende geweldige plek waar mensen kunnen samenwerken, praten en ontdekken wat de toekomst biedt. Om een ​​nieuwe community te maken, upgrade naar Premium.',
            AddCommunityName: 'Gemeenschaps Naam',
            AddCommunityPlaceholder: 'BV. DEJ073',
            CreateCommunityPlaceholder: 'BV. Mulberry Street School',

            AddJoinCode: 'Voer de deelnamecode in',
            AddJoinCodeDescription: 'Sluit je aan bij de community van je klasgenoten om samen te werken, te praten en te ontdekken wat de toekomst biedt.',
            AddJoinCodeName: 'Lid worden van de code',
            AddJoinCodePlaceholder: 'BV. 0XF78D',

            CreateFolder: 'Een map maken',
            CreateFolderDescription: 'Verzamel mensen in uw gemeenschap in gerichte groepen om alles te bespreken. Bouw gezamenlijke gesprekken, peilingen en meer.',
            CreateFolderName: 'Mapnaam',
            CreateFolderPlaceholder: 'BV. Europese geschiedenisles',

            ConfirmDelete: 'Verwijder map',
            ConfirmDeleteDescription: 'Weet je zeker dat je wilt verwijderen',
            ConfirmDeleteDiscussion: 'Discussie verwijderen',
            ConfirmDeleteResponse: 'Reactie verwijderen',
            ConfirmDeleteBackground: 'Achtergrond verwijderen',
            ConfirmDeleteBackgroundDescription: 'Weet u zeker dat u deze achtergrond wilt verwijderen?',

            ConfirmRemoveMember: 'Lid verwijderen',
            ConfirmRemoveMemberDescription: 'Weet je zeker dat je',
            ConfirmRemoveMemberDescription2: ' wilt verwijderen',

            CreateFolderStart: 'Maak een map om samen te werken',
            Duplicate: 'Duplicaat',
            ConfirmDuplicate: 'Dubbele map',
            ConfirmDuplicateDescription: 'wordt gedupliceerd zonder de antwoorden. Voer een unieke naam in voor de nieuwe map.',
            UniqueName: 'Unieke naam',

            InviteMembers: 'Leden uitnodigen',
            InviteMembersDescription: 'Voer de e-mailadressen in van de mensen die u wilt uitnodigen voor uw gemeenschap.',
            InviteMembersPlaceholder: 'example@email.com',
            InviteButton: 'Verstuur uitnodiging',
            InviteSent: 'Verzonden',

            EditCommunities: 'Bewerk gemeenschappen',
            EditCommunitiesDescription: 'Hernoem, verwijder of dupliceer uw gemeenschappen.',

            EditFolders: 'Bewerk mappen',
            EditFoldersDescription: 'Hernoem, verwijder of dupliceer uw mappen.',
        },
        Modal: {
            Cancel: 'Annuleren',
            Submit: 'Indienen',
            Remove: 'Verwijderen',
            SubmitContinue: 'Verzenden en doorgaan',
            Loading: 'Laden',
            Uploading: 'Uploaden',
            Close: 'Dichtbij',
            ShortDescription: 'Prompt Beschrijving (Vereist)',
            ShortDescriptionPlaceholder: 'BV. Spreek alstublieft 2 minuten over...',
            SpaceType: 'Ruimtetype',
            Conversation: 'Gesprek',
            ConversationDescription: 'Met gesprekken kun je op elk moment opnemen, reageren en samenwerken met klasgenoten.',
            Poll: 'Peiling',
            PollDescription: 'Klaar om opties af te wegen of consensus te bereiken? Start een poll en laat de community aan het woord.',
            VideoRecording: 'Video-opname',
            AttachFile: 'Bijlagen',
            YoutubeLink: 'YouTube-URL',
            NarchivesLink: 'Nationaal archief link',
            FigmaLink: 'FigJam link',
            GdriveLink: 'Google Drive link',
            CanvaLink: 'Canva link',
            ViewPreview: 'Voorbeeld sluiten',
            ClosePreview: 'voorbeeld bekijken',

            ChangeDescription: 'Wijzig uw volledige naam of uw profielafbeelding wijzigen.',
            ProfileImage: 'Profielafbeelding',
            ChangeHandle: '@ Naam',
            ChangeHandlePlaceholder: 'Naam (geen @, geen spaties)',

            JoinByCode: 'Doe mee met code',
            CreateNew: 'Maak nieuw',
            RelatedVideos: 'Gerelateerde videos',
            RelatedArticles: 'Gerelateerde artikelen',
            NoResults: 'Geen resultaten',

            PremiumTitle: 'Premium ontgrendelen',
            PremiumDescription: 'Ontgrendel de krachtigste AI-workflowtools die u uren werk besparen, hele lessen maken van uw prompts en de betrokkenheid een boost geven',
            Upgrade: 'Ontgrendel voor $12 / Maand',
            UpgradeYearly: 'Ontgrendel voor $99 / Jaar',
            UpgradeEducator: 'Ontgrendel voor $6 / Maand',
            UpgradeYearlyEducator: 'Ontgrendel voor $58 / Jaar',
            YouArePremium: 'Je bent Premium',
            PremiumThankYou: 'bedankt dat je een',
            Monthly: 'Maandelijks',
            Yearly: 'Jaarlijks',
            PremiumMember: 'Gravity Premium lid',
            NextRenewal: 'Volgende verlenging',
            EducatorDiscount: '50% Korting',
            Savings: '30% Korting',
            SavingsEducator: '60% Korting',
            ContactUs: 'Neem contact op',
            IAmEducator: 'Ik ben een K-12 opvoeder! (50% korting)',

            Yay: 'Yay!',
            Prompt: 'Prompt',
            Response: 'Reactie',
            Created: 'Gemaakt!',
            OnYourWay: 'Je bent nu op weg naar spannende samenwerking!',
            Notify: 'Melding sturen',
            Return: 'Terug',
            NotifyAll: 'Meld iedereen',
            MembersOf: 'van de leden',

            AIPromptAssistant: 'AI Prompt Assistant',
            PromptScript: 'Prompt Script',
            PromptScriptPlaceholder: 'Plak hier uw script. Dit gebied wordt niet opgenomen.',
            Hide: 'Verbergen',
            Show: 'Show',
            Update: 'Update',
            Private: 'Privaat',
            New: 'Nieuw',

            Transcript: 'Transcriptie',
            Translation: 'Vertaling',
            SharePublic: 'Delen Openbaar',
            Next: 'Volgende',
            HeyThere: 'Hallo daar',
            FollowUpText: 'Naar aanleiding van uw laatste antwoord heb ik een vervolgvraag voor u',

            Leave: 'Vertrekken',
            RecordEmbed: 'Recorder-insluitcode',
            AllowPublic: 'Sta openbare reacties op deze vraag toe, waar dan ook',
            CopyCode: 'Kopieer Code',
            Copied: 'Gekopieerd!',
        },
        Content: {
            JoinCode: 'Deelnamecode',
            Welcome: 'Welkom',
            NoUpdates: 'Geen updates sinds je laatste login',
            CreateASpace: 'Creëer Nieuwe Ruimte',

            CreateNewPrompt: 'Nieuwe prompt maken',
            CreateSpaceDescription: 'Neem je gezicht, stem of scherm op en ga aan de slag. Voeg informatie toe om anderen te helpen samenwerken!',
            CreateSpaceName: 'Prompt Titel (Vereist)',
            CreateSpacePlaceholder: 'BV. Discussie over het tijdperk van de Verlichting',
            ResponseTitle: 'Titel van antwoord (vereist)', 

            NoPromptsYet: 'Nog geen aanwijzingen',
            SelectAFolder: 'Selecteer een map',

            Back: 'Terug'
        },
        Recorder: {
            Video: 'Video',
            Screen: 'Scherm',
            Voice: 'Spraak',
            Combo: 'Combo',
            Upload: 'Uploaden',
            Resume: 'HERVAT',
            Pause: 'PAUZE',
            Recording: 'OPNEMEN',

            Beta: 'BETA',
            New: 'NIEUW',

            Speaking: 'Spreken',
            Originality: 'Originaliteit',
            Tone: 'Stijl',
            Neutral: 'Neutraal',
            Positive: 'Positief',
            Negative: 'Negatief',

            FollowUp: 'Vervolg vraag',
            QuestionType: 'Vraagtype',

            InfoHover: 'Gravity maakt gebruik van technologie om reacties te detecteren door generatieve AI zoals ChatGPT. (Dit is een experimentele functie)',
            ToneHover: 'Gravity maakt gebruik van technologie om toon te helpen detecteren op basis van videotranscriptie. (Dit is een experimentele functie)',
            FollowUpHover: 'Maak gebruik van Gravity AI om na een reactie een gepersonaliseerde vervolgvraag te creëren.',
            QuestionTypeHover: 'Selecteer de stijl van de individuele vervolgvraag die na een antwoord wordt gesteld.',

            PublishDate: 'Publicatiedatum',
        },
        SpacesView: {
            Conversation: 'Gesprek',
            Poll: 'Peiling',
            Members: 'Leden',
            Joined: 'Toegetreden',
            Anonymous: 'Anoniem',
            ScheduledFor: 'Gepland voor',
            View: 'Bekijken',
            Hide: 'Verbergen',
            Response: 'Reacties',
            Responses: 'Reacties',
        },
        AssetsView: {
            Teacher: 'Docent',
            Student: 'Student',
            Respond: 'Reageer',
            To: ' op',
            RespondTo: 'Reageer op',
            DocumentAttachment: 'Document',
            YoutubeAttachment: 'YouTube',
            NarchivesAttachment: 'National Archives',
            NoResponses: 'Nog geen amtwoorden',
            View: 'Bekijk',
            Reply: 'Antwoord',
            Replies: 'Antwoorden',

            ResponseDescriptionPlaceholder: 'BV. Voordat we een beslissing nemen...',
            SidebarWith: 'Zijbalk met',
            ThreadDescriptionPlaceholder: 'BV. Kun je dat detail een beetje uitbreiden...',

            Back: 'Terug naar',
            Keywords: 'Trefwoorden',
            Insights: 'Inzichten',
            EditKeywords: 'Bewerk trefwoorden',
            EditKeywordsDescription: 'Houd minimaal 5 trefwoorden aan',

            Settings: 'Promptinstellingen',
            SettingsDescription: 'Beheer de functies van de prompt, bewerk de publicatiedatum, enz.',
            Record: 'Opnemen',
            Text: 'Tekst',

            AIGenerated: 'Door AI gegenereerde vervolgvraag',
            ClickFor: 'Klik voor',

            SorryVideo: 'Sorry, de media wordt niet geladen...',
            Retry: 'Retry',
        },
        ThreadView: {
            Collapse: 'Dichtbij',
            Back: 'Terug naar prompt',
            TypeResponse: 'typ een antwoord...',
            Submitted: 'Doen!',
            Failed: 'Mislukt',
        },
        Upload: {
            DropFileHere: 'Zet het bestand hier neer',
            Or: 'OF',
            SelectFile: 'Selecteer bestand',
            SelectVideo: 'Selecteer .mp4',
            SetAsVideo: 'Instellen als video',
            FileName: 'Bestandsnaam',
            FileType: 'Bestandstype',
            Reselect: 'Opnieuw selecteren'
        },
        Video: {
            Transcripts: '*Transcripties worden automatisch gegenereerd. Er kunnen onjuistheden voorkomen.',
            Select: 'Selecteer',
            English: 'Engels',
            Spanish: 'Spaans',
            French: 'Frans',
            German: 'Duits',
            Italian: 'Italiaans',
            Portuguese: 'Portugees',
            Dutch: 'Nederlands',
            Russian: 'Russisch',
            Chinese: 'Chinees',
            Japanese: 'Japans',
            Korean: 'Koreaans',
            Arabic: 'Arabisch',
            Hindi: 'Hindi'
        },
        Embed: {
            PoweredBy: 'Aangedreven door',
            JoinConversation: 'Doe mee aan het gesprek',
            EnterDetails: 'Vul hieronder uw gegevens in',
            BackGuest: 'Terug naar gastlogin',
            ToGetStarted: 'beginnen!',
            OrUse: 'Of gebruik uw',
            Name: 'Naam',
            Email: 'E-mail',
            StartRecording: 'Begin met opnemen',
            Success: 'Succes!',
            StayTuned: 'Blijf op de hoogte van meer WRAD prompts!',
            VisitGravity: 'Bezoek usegravity.io voor meer samenwerking!',
            Sorry: 'Sorry, deze prompt kan niet worden ingesloten',
        }
    }
};
  
export default strings;